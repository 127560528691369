<template>
  <div class="main-container">
    <div class="text-center">
      <img :src="emptyPng" alt="" class="emptyPng" />
    </div>
    <p class="c-8E929B rem30 mt12 text-center">暂无企业信息，请先添加企业</p>
    <div class="flex just-center mt20">
      <div class="flex just-center align-center add-company" @click="addCompany">
        <img :src="addPng" alt="" />
        <span>添加企业</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      addPng: require('@/assets/images/credit/add.png'),
      emptyPng: require('@/assets/images/credit/empty.png'),
    }
  },
  created() {},
  computed: {},
  methods: {
    addCompany() {
      this.$router.push('/add-company-info')
    },
  },
}
</script>
<style lang="less" scoped>
.emptyPng {
  width: 4.74rem;
  height: 3.34rem;
}
.add-company {
  width: 2.4rem;
  height: 0.84rem;
  line-height: 0.84rem;
  background: #f4f7ff;
  border-radius: 4px;
  border: 1px solid #4d7bfe;
  img {
    width: 0.3rem;
    height: 0.3rem;
  }
  span {
    color: #4d7bfe;
    font-size: 0.34rem;
    margin-left: 0.12rem;
  }
}
</style>
